#header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 8vh;
  font-family: 'Pacifico', cursive;

  background-color: var(--header-background-color);
  color: var(--header-color);
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: var(--border-color);
}

.mapboxgl-popup-content {
  background-color: var(--popup-background-color);
}

.mapboxgl-popup-close-button {
  color: white;
}

#content {
  display: flex;
  height: 92vh;
}

#sidebar {
  display: flex;
  flex-direction: column;

  width: 350px;
  flex-grow: 0;
  flex-shrink: 0;

  background-color: var(--sidebar-background-color);
  color: var(--sidebar-color);
}

@media only screen and (max-width: 768px) {
  #header {
    font-size: 1.5rem;
  }

  #content {
    flex-direction: column;
  }

  #sidebar {
    width: 100%;
    height: 40vh;
  }

  #search-bar .found-carts-and-yelp {
    display: none;
  }

  #search-bar input {
    font-size: 16px;
  }

  #food-carts-map {
    flex: 1;
  }

  #search-bar {
    padding: 0.5rem;
  }

  li.result-cart {
    padding: 0.5rem;
  }
}
