#search-bar {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0.5rem 1rem;
}

.search-bar-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#search-bar input {
  margin: 0;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  flex: 1;
  height: 45px;
  padding: 10px 8px;
  border: 1px solid;
  border-color: var(--border-color);
  background-color: transparent;
}

.found-carts-and-yelp {
  margin-top: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.found-carts {
  font-size: 0.75rem;
  font-style: italic;
}

.found-carts-and-yelp > svg {
  height: 24px;
  width: 100px;
  fill: var(--sidebar-icon-color);
}
