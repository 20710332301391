ul#results {
  list-style: none;
  overflow-y: scroll;
}

li.result-cart {
  padding: 1rem;
}

li.result-cart:hover {
  cursor: pointer;
}
