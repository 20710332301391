.vendor-cart-name {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 12px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--sidebar-cart-name-color);
  font-family: 'Poppins', sans-serif;
}

.vendor-metadata {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;

  color: var(--sidebar-color);
}

span.vendor-text {
  line-height: 1.5;
}

.vendor-metadata > svg {
  height: 24px;
  width: 24px;
  margin-right: 0.5rem;

  fill: var(--sidebar-icon-color);
}

.menu-button {
  background-color: var(--header-color);
  padding: 4px 10px;
  border-radius: 14px;
  width: fit-content;

  font-family: 'Poppins', sans-serif;
}

a.food-carts {
  font-family: 'Poppins', sans-serif;
  color: var(--header-background-color);
}

a,
a:visited {
  text-transform: none;
  text-decoration: none;
}
