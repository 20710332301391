@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:wght@400;800&family=Titillium+Web&display=swap');

:root {
  --sidebar-color: #3e5641;
  --sidebar-cart-name-color: #53131e;
  --sidebar-background-color: #f3ede9;

  --header-color: #bc8034;
  --header-background-color: #53131e;

  --popup-color: #f3ede9;
  --popup-background-color: #3e5641;

  --sidebar-icon-color: #3e5641;
  --border-color: #3e5641;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
