.vendor-popup-cart-name {
  text-transform: uppercase;
  font-weight: bolder;
  color: var(--popup-color);
  font-family: 'Poppins', sans-serif;
  font-size: 1.05rem;
}

.vendor-popup-metadata {
  display: flex;
  flex-direction: column;
  color: var(--popup-color);
  margin: 5px 0;
}

.vendor-popup-metadata:last-child {
  margin-bottom: 0;
}

.vendor-popup-metadata > .label {
  font-weight: bold;
  font-family: 'Titillium Web', sans-serif;
}

a,
a:visited {
  color: var(--popup-color);
}
