#error {
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--header-background-color);
}

.error-emoji {
  font-size: 5rem;
}
